<script lang="ts">
	import Logo from '~/components/atoms/Logo.svelte';
	import RentalSVG from '~/icons/img_box_fill.svg?raw';
	import CorporateSVG from '~/icons/corporate.svg?raw';
	import CheckRouteSVG from '~/icons/check_route.svg?raw';
	import RouteMapSVG from '~/icons/route-map.svg?raw';
	import BlogSVG from '~/icons/blog.svg?raw';
	import ourOfferingSVG from '~/icons/offering.svg?raw';
	import howItWorksSVG from '~/icons/howItWorks.svg?raw';
	import adsWithCityfloSVG from '~/icons/ads-with-cityflo.svg?raw';
	import { getHomePermalink, getBlogPermalink } from '~/utils/permalinks';
	import { onMount } from 'svelte';
	import { getParam } from '~/utils/utils';
	import { isMobile } from '~/utils/store';

	let expanded = false;

	// Pending to add
	export let downloadAppLink: string;
	export let legalPage: boolean = false;
	export let routePage: boolean = false;

	const headerPrimary = legalPage;

	function toggleMenu() {
		document.getElementById('blur-layer')?.classList.remove('backdrop-blur-xl');
		document.getElementById('blur-layer')?.classList.remove('mix-blend-lighten');
		document.body.classList.toggle('overflow-hidden');
		document.getElementById('landing-id')?.classList.toggle('slide-in-blur');
		expanded = !expanded;
	}

	function handleIsMobile(event) {
		if (event.matches) $isMobile = true;
		else $isMobile = false;
	}

	// For the case when user clicks on find my bus on home page
	function mobileOnlyClose() {
		// isHeaderHidden = true;
		if (expanded) toggleMenu();
	}

	// let lastScrollValue = 0;
	let isHeaderHidden = false;

	let bgWhite = false;
	// let isScrolling = false;

	$: showWhiteBG = !isHeaderHidden && bgWhite;

	onMount(() => {
		// lastScrollValue = window.scrollY || document.documentElement.scrollTop;
		// document.addEventListener('scroll', (event) => {
		// 	if (!isScrolling) {
		// 		handleScroll(event);
		// 	}
		// });
		// document.addEventListener('wheel', handleWheel, { passive: false });
		handleIsMobile(window.matchMedia('(max-width: 767px)'));
	});

	// function handleScroll(event) {
	// 	const currentScrollPos = document.documentElement.scrollTop;
	// 	bgWhite = currentScrollPos >= 40;
	// 	isScrolling = false;
	// }

	// function handleWheel(event) {
	// 	isScrolling = true;
	// 	const deltaY = event.deltaY;
	// 	// If the user is scrolling up, show the header
	// 	if (deltaY > 0) {
	// 		isHeaderHidden = true;
	// 	} else {
	// 		isHeaderHidden = false;
	// 	}
	// 	isScrolling = false;
	// }

	const links = [
		// {
		// 	icon: HomefillSVG,
		// 	href: '/find-my-bus/',
		// 	// rel: 'noopener external',
		// 	name: 'About Us',
		// },
		{
			id: 'find-my-bus-nav-menu',
			icon: CheckRouteSVG,
			href: '/#find-my-bus',
			// rel: 'noopener external',
			name: 'Find My Bus',
		},
		{
			id: 'our-offerings-nav-menu',
			icon: ourOfferingSVG,
			href: '/#our-offering',
			// rel: 'noopener external',
			name: 'Our Offerings',
		},
		{
			id: 'how-it-works-nav-menu',
			icon: howItWorksSVG,
			href: '/#how-it-works',
			name: 'How It Works',
		},
		{
			id: 'available-routes',
			icon: RouteMapSVG,
			href: '/routes/mumbai/',
			// rel: 'noopener external',
			name: 'Available Routes',
		},
		{
			id: 'rentals-nav-menu',
			icon: RentalSVG,
			href: '/rentals/',
			// rel: 'noopener external',
			name: 'Rentals',
		},
		{
			id: 'corporate-nav-menu',
			icon: CorporateSVG,
			href: '/corporate/',
			// rel: 'noopener external',
			name: 'Cityflo Corporate',
		},
		{
			id: 'ads-suite-nav-menu',
			icon: adsWithCityfloSVG,
			href: '/ads-with-cityflo/',
			// rel: 'noopener external',
			name: 'Ads with Cityflo',
		},
		{
			id: 'blog-nav-menu',
			icon: BlogSVG,
			href: getBlogPermalink(),
			rel: 'noopener external',
			name: 'Blog',
			target: 'blog',
		},

	];

	function addBlur() {
		document.getElementById('blur-layer')?.classList.add('backdrop-blur-xl');
		document.getElementById('blur-layer')?.classList.add('mix-blend-lighten');
	}

	onMount(() => {
		// handleDialogOpen();

		const mql = window.matchMedia('(max-width: 767px)');

		mql.addEventListener('change', handleIsMobile);

		handleIsMobile(mql);

		return () => {
			mql.removeEventListener('change', handleIsMobile);
		};
	});

	// Logic to remove header , footer and add center logo when user open policy pages from mobile Application
	let source: string | null;
	let hideHeaderForApp : boolean

	onMount(() => {
		source = getParam('source');

		const footerElement = document.getElementsByTagName('footer')[0];

		if (source === 'app') {
			footerElement.style.display = 'none';
			// handle routes/ and route/[route-name] case
			if (window.location.pathname.includes('route')) 
				hideHeaderForApp = true;
		}

		if (window.location.pathname.includes('route-map')) 
			hideHeaderForApp = true
		
	});

	const offset = 100;
	const tolerance = 10;

	let y = 0;
	let lastY = 0;

	function deriveHiddenState(y: number, dy: number) {
		bgWhite = y > offset;

		if (y < offset) {
			return false;
		}

		if (Math.abs(dy) <= tolerance) {
			return isHeaderHidden;
		}

		if (dy < 0) {
			return true;
		}

		return false;
	}

	function updateClass(y: number) {
		const dy = lastY - y;
		lastY = y;
		return deriveHiddenState(y, dy);
	}

	$: isHeaderHidden = updateClass(y);
</script>

<svelte:window bind:scrollY={y} />
<header
	class={`${hideHeaderForApp ? 'hidden' : 'block'} text-neutral-25 fixed top-0 z-[100] flex-none mx-auto w-full bg-transparent md:backdrop-blur-0 overflow-hidden transition-transform ${
		expanded ? 'h-[calc(100dvh_+_90px)]' : ''
	} ${routePage ? 'px-6 md:px-10' : 'px-6 md:px-16'} ${isHeaderHidden || (routePage && isMobile) ? '-translate-y-full' : ''} ${!showWhiteBG ? 'opacity-95' : ''}`}
	class:header-bg-gradient={!showWhiteBG && !(legalPage || routePage)}
	class:bg-blend-multiply={!legalPage}
	class:-translate-y-full={isHeaderHidden}
	id="header"
	style={`${showWhiteBG && !expanded ? 'background-color: white' : ''}`}
>
	<div
		id="blur-layer"
		class="bg-primary absolute inline top-4 right-8 w-8 h-8 rounded-full -z-10 scale-0 opacity-0"
		class:scale-bg={expanded}
		on:animationend={addBlur}
		class:hidden={routePage}
	/>
	<div class="relative py-3 mx-auto h-fit grid w-full md:flex md:justify-between">
		<div class={`flex ${source === 'app' ? 'justify-center' : 'justify-between '}`}>
			<a id="home-page-header" class="flex items-center nav-in" href={getHomePermalink()}>
				<span class="hover:scale-125 transition duration-150 ease-in-out">
					<Logo
						classList={` ${source === 'app' ? 'h-12' : 'h-8'} text-accent transition-logo ${showWhiteBG ? '' : 'mix-blend-exclusion'}`}
					/>
				</span>
			</a>
			<div class={`${source === 'app' ? 'hidden' : ''} hamburger flex items-center md:hidden`}>
				<button
					id="toggle-menu-nav-menu"
					aria-label="Toggle Menu"
					data-aw-toggle-menu
					class={`ml-1.5 rounded-lg text-sm py-2.5 inline-flex items-center click ${
						headerPrimary || showWhiteBG ? 'text-primary' : 'text-neutral-25'
					}`}
					class:expanded
					type="button"
					on:click={toggleMenu}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						preserveAspectRatio="xMidYMid meet"
						viewBox="0 0 24 24"
						class="w-6 h-6 transition-transform"
						><g
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
							><path class="origin-[12px_4px]" d="M0 4h24" />
							<path class="origin-[12px_12px]" d="M0 12h24" />
							<path class="origin-[12px_20px]" d="M0 20h24" /></g
						></svg
					>
				</button>
			</div>
		</div>
		<nav
			class={`absolute w-full overflow-x-hidden z-10 md:relative md:-translate-y-0 h-[calc(100svh-100px)]  md:h-auto md:w-auto md:flex md:overflow-y-visible flex flex-col pb-4 md:pb-0 md:gap-24 items-start ${
				expanded ? 'slide-in' : '-translate-y-[99svh] slide-out'
			}`}
			aria-label="Main navigation"
			style={`${routePage ? 'display: none;' : ''}`}
		>
			<menu
				class="mb-4 md:mb-0 grid grid-rows-[repeat(7,fit-content)] md:flex md:space-around md:gap-8 md:pt-0 w-full md:w-auto text-xl md:text-base"
			>
				{#each links as link, index}
					<li class={`${(legalPage || showWhiteBG) ? 'md:text-primary' : 'md:text-accent'} group text-neutral-25`}>
						<a
							id={link.id}
							class="grid grid-cols-[35px_1fr] h-full md:grid-cols-none place-content-center"
							class:nav-in={!link.rel}
							class:nav-out={!!link.rel}
							href={link.href}
							on:click={mobileOnlyClose}
							rel={link.rel}
							target={link.target}
						>
							<!-- <img class="md:hidden place-self-center" src={link.icon} alt="" fetchpriority="low" /> -->
							<span
								class="md:hidden place-self-center"
								class:showIcon={expanded}
								style={`animation-delay: ${index * 75 + 500}ms`}>{@html link.icon}</span
							>
							<span
								class={`flex text-center p-4 md:py-2 font-normal md:transition md:duration-150 md:ease-in-out md:px-0 link-hover-nav-underline after:top-3/4 ${
									legalPage ? 'after:bg-primary' : ''
								}`}
								class:slideInLink={expanded}
								style={`--stagger: ${index}`}
							>
								{link.name}
							</span>
						</a>
					</li>
				{/each}
			</menu>
			<a
				id="download-the-app-nav-menu"
				class="md:hidden btn btn-white px-8 mt-auto opacity-0 nav-out"
				href={downloadAppLink}
				class:showIcon={expanded}
				style={`animation-delay: 500ms`}
			>
				Download The App
			</a>
		</nav>
	</div>
</header>

<style>
	.header-bg-gradient {
		background: linear-gradient(180.21deg, #00253f -10.4%, rgba(255, 255, 255, 0) 99.81%);
	}

	@media screen and (max-width: 768px) {
		@keyframes slide-out {
			0% {
				transform: translateY(10svh);
				opacity: 1;
			}
			50% {
				transform: translateY(10svh);
				opacity: 0;
			}
			100% {
				transform: translateY(-100svh);
				opacity: 0;
			}
		}

		@keyframes slide-in {
			0% {
				transform: translateY(-100svh);
				opacity: 0;
			}
			50% {
				transform: translateY(10svh);
				opacity: 0;
			}
			100% {
				transform: translateY(10svh);
				opacity: 1;
			}
		}

		@keyframes slide-in-link {
			0% {
				transform: translateX(120000ch);
				opacity: 0;
			}

			96.4% {
				opacity: 0;
			}

			100% {
				transform: translateX(0);
				opacity: 1;
			}
		}

		@keyframes show-icon {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}

		@keyframes scale-separator {
			0% {
				opacity: 0;
				transform: scaleX(0);
			}

			94% {
				opacity: 0;
				transform: scaleX(0);
			}

			100% {
				opacity: 1;
				transform: scaleX(1);
			}
		}

		@keyframes scale-bg {
			0% {
				opacity: 1;
				transform: translate(-35vw, 49dvh) scale(0);
			}

			100% {
				opacity: 1;
				transform: translate(-35vw, 49dvh) scale(40);
			}
		}

		.slide-in {
			animation: slide-in 300ms cubic-bezier(0.19, 1, 0.22, 1) 1 forwards;
		}

		.slide-out {
			animation: slide-out 300ms cubic-bezier(0.19, 1, 0.22, 1) 1 forwards;
		}

		.slideInLink {
			animation: slide-in-link 15s cubic-bezier(0.19, 1, 0.22, 1) 1 forwards;
			transform: translateX(120000ch);
			animation-delay: calc(-14.2s + var(--stagger) * 75ms);
			opacity: 0;
		}
		/* cubic-bezier(0.32, 0, 0.67, 0) */
		.showIcon {
			animation: show-icon 150ms 1 forwards;
			opacity: 0;
		}

		.scaleSeparator {
			animation: scale-separator 15s cubic-bezier(0.19, 1, 0.22, 1) 1 forwards;
			transform: scaleX(0);
		}

		.scale-bg {
			animation: scale-bg 400ms cubic-bezier(0.19, 1, 0.22, 1) 1 forwards;
		}
	}
</style>
